import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import Banner from "../components/Banner/Banner"
import Button from "../components/Button/Button"
import CTA from "../components/CTA/CTA"
import TxtImg from "../components/TxtImg/TxtImg"
import Underline from "../components/Underline/Underline"
import MetaImage from "../images/office.jpeg"
import * as HomeStyles from "./index.module.css"

export default function Home({ data }) {
  return (
    <Layout>
      <Helmet>
      <meta charSet="utf-8" />
        <title>Paula Abbey Counselling & Psychotherapy</title>
        <meta name="title" content="Paula Abbey Counselling & Psychotherapy" />
        <meta name="description" content="I offer counselling and psychotherapy with a person-centered approach, providing you with a safe and confidential space to reflect and explore your world." />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://paulaabbey.co.uk/" />
        <meta property="og:title" content="Paula Abbey Counselling & Psychotherapy" />
        <meta property="og:description" content="I offer counselling and psychotherapy with a person-centered approach, providing you with a safe and confidential space to reflect and explore your world." />
        <meta property="og:image" content={MetaImage} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://paulaabbey.co.uk/" />
        <meta name="twitter:title" content="Paula Abbey Counselling & Psychotherapy" />
        <meta name="twitter:description" content="I offer counselling and psychotherapy with a person-centered approach, providing you with a safe and confidential space to reflect and explore your world." />
        <meta name="twitter:image" content={MetaImage} />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Paula Abbey Counselling & Psychotherapy",
              "image": "https://paulaabbey.co.uk/static/858cd233be5126b994166fd61763d2e3/4ea8f/paula-2.webp",
              "@id": "",
              "url": "https://paulaabbey.co.uk",
              "telephone": "07898437229",
              "priceRange": "£40 - £50",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "41 Firecrest Way, Kelsall",
                "addressLocality": "Tarporley",
                "postalCode": "CW6 0RX",
                "addressCountry": "GB"
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Tuesday",
                  "Wednesday",
                  "Thursday"
                ],
                "opens": "09:00",
                "closes": "17:00"
              } 
            }
          `}
        </script>
      </Helmet>
      <Banner image={data.background.childImageSharp.fluid}>
        <div>
          <h1 style={{fontWeight: 700, textAlign: "center", margin: "0 auto", textTransform: "uppercase"}}>Paula Abbey</h1>
          <h4 style={{textAlign: "center", margin: "0 auto", paddingBottom: 50, fontWeight: 300}}>Counselling & Psychotherapy</h4>
        </div>
      </Banner>
      <section className={HomeStyles.intro}>
        <div className={HomeStyles.introContainer}>
          <Underline>
            <h2>Hello and welcome</h2>
          </Underline>
          <p>Life can be difficult at times, and can leave us feeling overwhelmed, confused or alone. Whilst these emotions are a normal part of being human, our need to be heard, understood, and supported can be unfulfilled by friends and family for a variety of reasons. Speaking to an impartial, trained professional can provide a valuable source of support through these distressing times.</p>
          <p>Although it may feel difficult, by asking for help you are taking the first important step towards healing and positive change. You will be helped to explore your feelings and behaviours, to gain useful insights and perspective and to uncover your inner resources that can help you live a more fulfilling life.</p>
        </div>
      </section>
      <section>
        <TxtImg split="third" imgFirst={true} imgSrc={data.headshot.childImageSharp.fluid} imgAlt="Paula Abbey headshot">
          <p style={{fontSize: "var(--type-m)", lineHeight: "1.4", color: "var(--primary-900)", fontWeight: 700}}>"I’m Paula, a fully trained, registered and insured therapist, offering person-centred counselling and psychotherapy to help people emotionally heal, grow and achieve their full potential."</p>
          <Button type="internal" colour="primary" to="/about/">Learn more about me</Button>
        </TxtImg>
      </section>
      <section className={HomeStyles.servicesSection}>
        <TxtImg split="half" imgFirst={false} imgSrc={data.office.childImageSharp.fluid} imgAlt="two people on a couch">
          <Underline>
            <h2>Therapy your way</h2>
          </Underline>
          <p>I aim to make the process of finding a therapist as comfortable as possible. Your autonomy is very important to me, therefore I suggest an initial 20 minute telephone session, to give us time to decide whether we will work well together.</p>
          <p>There are also options of how to access therapy:</p>
          <ul>
            <li>Face to Face</li>
            <li>Online</li>
            <li>Telephone</li>
          </ul>
          <Button type="internal" colour="primary" to="/services/">Read more about my services</Button>
        </TxtImg>
      </section>
      <section className={HomeStyles.cta}>
        <CTA>
          <h2>Make your appointment, today.</h2>
          <Button type="internal" colour="secondary" to="/appointments/">Get in touch</Button>
        </CTA>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query {
        background: file(relativePath: {eq: "images/dock.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 2000, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headshot: file(relativePath: {eq: "images/paula-2.jpeg"}) {
          childImageSharp {
            fluid(maxWidth: 900, quality: 72) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        office: file(relativePath: {eq: "images/office.jpeg"}) {
          childImageSharp {
            fluid(maxWidth: 900, quality: 72) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
    }
`